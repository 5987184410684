class BRHeader {
  constructor (name, component) {
    this.name = name
    this.component = component
    this._setBehavior()
  }

  _setBehavior () {
    this._setLoginBehavior()
    this._setSearchInputBehavior()
    this._setSearchCloseBehavior()
    this._setSearchButtonBehavior()
    this._setHideBehavior()
    this._setDropdownBehavior()
    window.addEventListener('resize', () => {
      this._setHideBehavior()
    })
  }

  _setLoginBehavior () {
    for (const login of this.component.querySelectorAll('.login button')) {
      login.addEventListener('click', (event) => {
        event.currentTarget.classList.add('d-none')
        for (const avatar of this.component.querySelectorAll('.avatar')) {
          avatar.classList.remove('d-none')
        }
      })
    }
  }

  _setSearchInputBehavior () {
    for (const input of this.component.querySelectorAll(
      '.search input[type="text"]'
    )) {
      input.addEventListener('focus', (event) => {
        for (const search of this.component.querySelectorAll('.search')) {
          search.setAttribute('active', '')
        }
        for (const menu of this.component.querySelectorAll('.menu')) {
          menu.classList.add('d-none')
        }
      })
    }
  }

  _setSearchCloseBehavior () {
    for (const searchClose of this.component.querySelectorAll(
      '.search button.search-close'
    )) {
      searchClose.addEventListener('click', (event) => {
        for (const search of this.component.querySelectorAll('.search')) {
          search.removeAttribute('active')
        }
        for (const menu of this.component.querySelectorAll('.menu')) {
          menu.classList.remove('d-none')
        }
      })
    }
  }

  _setSearchButtonBehavior () {
    for (const searchButton of this.component.querySelectorAll(
      '.search-btn button'
    )) {
      searchButton.addEventListener('click', (event) => {
        for (const search of this.component.querySelectorAll('.search')) {
          const clonedSearch = search.cloneNode(true)
          for (const container of this.component.querySelectorAll(
            '.flex-container'
          )) {
            clonedSearch.style.height = `${container.offsetHeight - 2}px`
          }
          for (const searchClose of clonedSearch.querySelectorAll(
            'button.search-close'
          )) {
            searchClose.addEventListener('click', (event) => {
              this._closePopMenu('.flex-container')
            })
          }
          this._openPopMenu(clonedSearch, '.flex-container')
          for (const input of clonedSearch.querySelectorAll('input[type="text"]')) {
            input.focus()
          }
        }
      })
    }
  }

  _setHideBehavior () {
    const functionalityList = this.component.querySelector('.functions')
    const functionalities = functionalityList.querySelectorAll('ul li')
    const linkList = this.component.querySelector('.links')
    const links = linkList.querySelectorAll('ul li')

    for (let i = links.length - 1; i > 0; i--) {
      if (window.screen.width < 992) {
        links[i].classList.add('d-none')
      } else {
        links[i].classList.remove('d-none')
      }
    }
    for (let i = functionalities.length - 1; i > 0; i--) {
      if (window.screen.width < 992) {
        functionalities[i].classList.add('d-none')
      } else {
        functionalities[i].classList.remove('d-none')
      }
    }
    if (functionalityList.scrollWidth > functionalityList.offsetWidth) {
      for (const trigger of this.component.querySelectorAll(
        '.functions button[data-trigger]'
      )) {
        trigger.classList.remove('d-none')
      }
      this._setHideFunctionsBehavior()
    } else {
      for (const trigger of this.component.querySelectorAll(
        '.functions button[data-trigger]'
      )) {
        trigger.classList.add('d-none')
      }
    }
    if (linkList.scrollWidth > linkList.offsetWidth) {
      for (const trigger of this.component.querySelectorAll(
        '.links button[data-trigger]'
      )) {
        trigger.classList.remove('d-none')
      }
      this._setHideLinksBehavior()
    } else {
      for (const trigger of this.component.querySelectorAll(
        '.links button[data-trigger]'
      )) {
        trigger.classList.add('d-none')
      }
    }
  }

  _setHideFunctionsBehavior () {
    const functionalityList = this.component.querySelector('.functions')
    const functionalities = functionalityList.querySelectorAll('ul li')
    const linkList = this.component.querySelector('.links')
    for (let i = functionalities.length - 1; i > 0; i--) {
      if (linkList.scrollWidth > linkList.offsetWidth) {
        functionalities[i].classList.add('d-none')
      } else if (
        functionalityList.scrollWidth > functionalityList.offsetWidth
      ) {
        functionalities[i].classList.add('d-none')
      }
    }
  }

  _setHideLinksBehavior () {
    const linkList = this.component.querySelector('.links')
    const links = linkList.querySelectorAll('ul li')
    for (let i = links.length - 1; i > 1; i--) {
      if (linkList.scrollWidth > linkList.offsetWidth) {
        links[i].classList.add('d-none')
      }
    }
  }

  _setDropdownBehavior () {
    for (const trigger of this.component.querySelectorAll(
      '.functions button[data-trigger], .links button[data-trigger]'
    )) {
      trigger.addEventListener('click', (event) => {
        for (const popmenu of this.component.querySelectorAll(
          '.actions .popmenu'
        )) {
          if (popmenu.hasAttribute('active')) {
            this._closeDropdown()
          }
        }
        this._openDropDown(event)
      })
    }
    window.document.addEventListener('click', (event) => {
      if (!this.component.contains(event.target)) {
        this._closeDropdown()
      }
    })
  }

  _openDropDown (event) {
    const list = document.createElement('div')
    list.classList.add('br-list', 'condensed')
    const ul = document.createElement('ul')
    let item

    for (const li of event.currentTarget.parentElement.querySelectorAll(
      'ul li'
    )) {
      if (li.classList.contains('d-none')) {
        item = li.cloneNode(true)
        item.classList.remove('d-none')
        ul.appendChild(item)
      }
    }
    list.appendChild(ul)
    this._openPopMenu(list, '.actions')
    for (const popmenu of this.component.querySelectorAll('.popmenu')) {
      popmenu.setAttribute(event.currentTarget.parentElement.classList[0], '')
    }
  }

  _closeDropdown () {
    this._closePopMenu('.actions')
  }

  _openPopMenu (element, containerSelector) {
    const popMenu = document.createElement('div')
    popMenu.classList.add('popmenu')
    popMenu.setAttribute('active', '')
    popMenu.appendChild(element)
    for (const container of this.component.querySelectorAll(
      containerSelector
    )) {
      if (!container.hasAttribute('active')) {
        container.setAttribute('active', '')
        container.prepend(popMenu)
      }
    }
  }

  _closePopMenu (containerSelector) {
    for (const element of this.component.querySelectorAll(containerSelector)) {
      for (const popMenu of element.querySelectorAll('.popmenu')) {
        element.removeChild(popMenu)
        element.removeAttribute('active')
      }
    }
  }
}

const headerList = []
for (const brHeader of window.document.querySelectorAll('.br-header')) {
  headerList.push(new BRHeader('br-header', brHeader))
}
