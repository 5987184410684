class BRPagination {
  constructor (name, component) {
    this.name = name
    this.component = component
    this.currentPage = 1
    this._setLayout()
    this._setBehavior()
  }

  _setLayout () {
    const ul = this.component.querySelector('ul')
    const pages = this.component.querySelectorAll('[data-page]')
    pages.forEach(page => {
      if (page.classList.contains('active')) {
        this.currentPage = parseInt(page.querySelector('a').innerText)
      }
      page.classList.remove('d-none')
    })

    if (this.currentPage === 1) {
      ul.querySelector('[data-previous-page]').setAttribute('disabled', '')
    } else {
      ul.querySelector('[data-previous-page]').removeAttribute('disabled')
    }

    if (this.currentPage === pages.length) {
      ul.querySelector('[data-next-page').setAttribute('disabled', '')
    } else {
      ul.querySelector('[data-next-page]').removeAttribute('disabled')
    }

    if (this.component.classList.contains('reduced')) {
      for (let page = 2; page < pages.length - 1; page++) {
        pages[page].classList.add('d-none')
      }
      if (!ul.querySelector('[data-next-interval]')) {
        ul.insertBefore(this._createIntervalElement('next'), ul.children[ul.children.length - 2])
      }
    } else if (pages.length > 9) {
      if (this.currentPage < 6) {
        if (ul.querySelector('[data-previous-interval]')) {
          ul.querySelector('[data-previous-interval]').remove()
        }
        for (let page = 7; page < pages.length - 1; page++) {
          pages[page].classList.add('d-none')
        }
        if (!ul.querySelector('[data-next-interval]')) {
          ul.insertBefore(this._createIntervalElement('next'), ul.children[ul.children.length - 2])
        }
      }
      if ((this.currentPage >= 6) && (this.currentPage < pages.length - 4)) {
        for (let page = this.currentPage - 4; page > 0; page--) {
          pages[page].classList.add('d-none')
        }
        if (!ul.querySelector('[data-previous-interval]')) {
          ul.insertBefore(this._createIntervalElement('previous'), ul.children[2])
        }
        for (let page = this.currentPage + 2; page < pages.length - 1; page++) {
          pages[page].classList.add('d-none')
        }
        if (!ul.querySelector('[data-next-interval]')) {
          ul.insertBefore(this._createIntervalElement('next'), ul.children[ul.children.length - 2])
        }
      }
      if (this.currentPage >= pages.length - 4) {
        if (ul.querySelector('[data-next-interval]')) {
          ul.querySelector('[data-next-interval]').remove()
        }
        for (let page = pages.length - 8; page > 0; page--) {
          pages[page].classList.add('d-none')
        }
        if (!ul.querySelector('[data-previous-interval]')) {
          ul.insertBefore(this._createIntervalElement('previous'), ul.children[2])
        }
      }
    }
  }

  _createIntervalElement (type) {
    const interval = document.createElement('li')
    interval.setAttribute(`data-${type}-interval`, '')

    const a = document.createElement('a')
    a.setAttribute('href', 'javascript:void(0)')

    const icon = document.createElement('i')
    icon.classList.add('fas', 'fa-ellipsis-h')

    a.appendChild(icon)
    interval.appendChild(a)

    return interval
  }

  _setBehavior () {
    for (const page of this.component.querySelectorAll('[data-page]')) {
      page.addEventListener('click', event => {
        this._selectPage(event.currentTarget)
      })
    }
    if (!this.component.classList.contains('reduced')) {
      this.component.querySelector('[data-previous-page]').addEventListener('click', () => {
        const index = this.currentPage - 1
        const pages = this.component.querySelectorAll('[data-page]')
        if (this.currentPage > 1) {
          this._selectPage(pages[index - 1])
        }
      })
      this.component.querySelector('[data-next-page]').addEventListener('click', () => {
        const index = this.currentPage - 1
        const pages = this.component.querySelectorAll('[data-page]')
        if (this.currentPage < pages.length) {
          this._selectPage(pages[index + 1])
        }
      })
    }
  }

  _selectPage (currentPage) {
    this.component.querySelectorAll('[data-page]').forEach(page => {
      page.classList.remove('active')
    })
    currentPage.classList.add('active')
    this._setLayout()
  }
}

const paginationList = []
for (const brPagination of window.document.querySelectorAll('.br-pagination')) {
  paginationList.push(new BRPagination('br-pagination', brPagination))
}

export default BRPagination
